import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuList: [],
    dynamicMenuList: [],
    barMenuList: [],
    requestLoading: false,
    hangCount: 0,
    cartSize: 0
  },
  mutations: {
    SET_MENU(state, data) {
      state.menuList = data;
    },
    SET_DYNAMIC_MENU(state, data) {
      state.dynamicMenuList = data;
    },
    SET_BAR_MENU(state, data) {
      state.barMenuList = data;
    },
    SET_REQUEST_LOADING(state, data) {
      state.requestLoading = data;
    },
    SET_HANG_COUNT(state, data) {
      state.hangCount = data;
    },
    SET_CART_SIZE(state, data) {
      state.cartSize = data;
    }
  },
  actions: {},
  modules: {},
});
