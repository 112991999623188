<template>

  <div class="main-bottom">
    <div class="cn">珠海市京美心理测量技术开发有限公司</div>
    <div class="en">KING MAY PSYCHOLOGICAL ASSESSMENT TECHNOLOGY DEVELOPMENT LTD.</div>
  </div>
</template>
<script>
export default {
  name: "footer",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>