<template>
  <a-modal
      title="关联数据"
      :visible="visible"
      @cancel="cancel"
      :width="1000"
      :footer="null"
  >
    <div>
<!--      <div class="search-box" style="margin-bottom: 20px">-->
<!--        <div class="search-form">-->
<!--          <a-form-model layout="inline">-->
<!--            <a-form-model-item label="姓名">-->
<!--              <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.name"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="报告编号">-->
<!--              <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.scoring_num"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="诊疗卡号">-->
<!--              <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.archives_num"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item>-->
<!--              <span class="search-btn"><img src="/images/search.png" alt=""></span>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model>-->
<!--        </div>-->
<!--      </div>-->
      <div class="list-box">
        <a-table :columns="columns" :data-source="list" :pagination="false">
          <template slot="role" slot-scope="text, record">
            <span v-if="record.edition == 1 && record.scoring_type == 1">幼儿家长评</span>
            <span v-if="record.edition == 1 && record.scoring_type == 2">幼儿教师评</span>
            <span v-if="record.edition == 2 && record.scoring_type == 1">儿童家长评</span>
            <span v-if="record.edition == 2 && record.scoring_type == 2">儿童教师评</span>
            <span v-if="record.edition == 3 && record.scoring_type == 1">成人自评</span>
            <span v-if="record.edition == 3 && record.scoring_type == 2">成人他评</span>
          </template>
          <template slot="edition" slot-scope="text, record">
            <span v-if="text == 1">幼儿版</span>
            <span v-if="text == 2">儿童版</span>
            <span v-if="text == 3">成人版</span>
          </template>
          <template slot="action" slot-scope="text, record" style="display: flex;align-items: center">
          <span class="op-box" style="cursor: pointer" @click="selectData(record)">选择关联数据</span>
          </template>
        </a-table>
      </div>
      <div class="content-page cu-page">
        <a-pagination :hideOnSinglePage="true" v-model="page.start" :page-size.sync="page.pageSize"
                      :total="page.totalRow" show-less-items @change="changePage"/>
      </div>
    </div>
  </a-modal>

</template>

<script>
import * as Api from "./api";

export default {
  props:{
    name: [String, Number],
    gender: [String, Number],
    edition: [String, Number],
    scoring_type: [String, Number],
    born_time: [String],
    type: [String],
    isVisible: [Boolean],
    id: [String]
  },
  watch: {
    name: {
      handler: function (e) {
        this.page.name = e;
      },
      immediate: true,
      deep: true
    },
    gender: {
      handler: function (e) {
        this.page.gender = e;
      },
      immediate: true,
      deep: true
    },
    edition: {
      handler: function (e) {
        this.page.edition = e;
      },
      immediate: true,
      deep: true
    },
    scoring_type: {
      handler: function (e) {
        this.page.scoring_type = e;
      },
      immediate: true,
      deep: true
    },
    isVisible: {
      handler: function (e) {
        this.visible = e;
        if (this.visible) {
          this.getList()
        }
      },
      immediate: true,
      deep: true
    },
    born_time: {
      handler: function (e) {
        this.page.born_time = e;
      },
      immediate: true,
      deep: true
    },
    type: {
      handler: function (e) {
        this.page.type = e;
      },
      immediate: true,
      deep: true
    },
    id: {
      handler: function (e) {
        this.page.id = e;
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      labelCol: {span: 8, style: {color: '#7E8589', fontWeight: '500'}},
      wrapperCol: {span: 14},
      columns: [
        {
          title: '编号',
          dataIndex: 'scoring_num',
          key: 'scoring_num',
          scopedSlots: {customRender: 'scoring_num'},
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {customRender: 'name'},
        },
        {
          title: '角色',
          dataIndex: 'role',
          key: 'role',
          scopedSlots: {customRender: 'role'},
        },
        {
          title: '版本',
          dataIndex: 'edition',
          key: 'edition',
          scopedSlots: {customRender: 'edition'},
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: {customRender: 'create_time'},
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: 120
        },
      ],
      page: {
        start: 1,
        limit: 10,
        totalRow: 0,
        pageSize: 0,
        name: '',
        gender: '',
        edition: '',
        scoring_type: '',
        born_time: '',
        type: '',
        id: ''
      },
      list: [],
      visible: false,
    }
  },
  created() {

  },
  methods: {
    async getList() {
      const res = await Api.List(this.page);
      if (res && res.code == 0) {
        this.list = res.page.list;
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
      }
    },
    changePage(e) {
      this.page.start = e;
      this.getList();
    },
    cancel() {
      this.visible = false;
      this.$emit('changeBindList', {type: 'cancel'});
    },
    selectData(data) {
      this.$emit('changeBindList', {type: 'select', data:data});
    }
  }
}
</script>

<style scoped>

</style>