
<style scoped>
.header {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  padding-bottom: 20px;
  color: #832c75;
}
.list .item {
  display: flex;
  border-bottom: 1px solid #f8f8f8;
  line-height: 36px;
}
.list .item .left,
.list .item .right {
  flex: 1;
  display: flex;
}
.list .item .left .title,
.list .item .right .title {
  width: 120px;
  font-weight: bold;
}
.list .item .left .content,
.list .item .right .content {
  color: #666;
  flex: 1;
}
.box {
  max-height: 600px;
  overflow-y: scroll;
}
</style>
<style scoped>
.header {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  padding-bottom: 20px;
  color: #832c75;
}
.list .item {
  display: flex;
  border-bottom: 1px solid #f8f8f8;
  line-height: 36px;
}
.list .item .left,
.list .item .right {
  flex: 1;
  display: flex;
}
.list .item .left .title,
.list .item .right .title {
  width: 120px;
  font-weight: bold;
}
.list .item .left .content,
.list .item .right .content {
  color: #666;
  flex: 1;
}
</style>
<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="cancel"
    :width="1000"
    :footer="null"
  >
    <div class="box">
      <div class="header">
        <span>基本信息</span>
      </div>
      <div class="base" v-if="base != null">
        <!-- 基本信息 -->
        <baseInfo :data="base" />
      </div>
      <!-- 完整版-->
      <div v-if="data.edition == 4">
        <div>
          <div class="header" style="margin-top: 20px">
            <span>{{ title }}</span>
          </div>
          <div class="list">
            <div class="item">
              <div class="left">
                <div class="title">积木:</div>
                <div class="content">{{ data.score_bd }}</div>
              </div>
              <div class="right">
                <div class="title">类同:</div>
                <div class="content">{{ data.score_si }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">背数:</div>
                <div class="content">{{ data.score_ds }}</div>
              </div>
              <div class="right">
                <div class="title">译码:</div>
                <div class="content">{{ data.score_cd }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">词汇:</div>
                <div class="content">{{ data.score_vc }}</div>
              </div>
              <div class="right">
                <div class="title">理解:</div>
                <div class="content">{{ data.score_co }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">填图:</div>
                <div class="content">{{ data.score_pcm }}</div>
              </div>
              <div class="right">
                <div class="title">划消:</div>
                <div class="content">{{ data.score_ca }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">常识:</div>
                <div class="content">{{ data.score_in }}</div>
              </div>
              <div class="right">
                <div class="title">算术:</div>
                <div class="content">{{ data.score_ar}}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">图画概念:</div>
                <div class="content">{{ data.score_pc }}</div>
              </div>
              <div class="right">
                <div class="title">字母-数字:</div>
                <div class="content">{{ data.score_ln }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">矩阵推理:</div>
                <div class="content">{{ data.score_mr }}</div>
              </div>
              <div class="right">
                <div class="title">符号检索:</div>
                <div class="content">{{ data.score_ss }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">积木无速度加分:</div>
                <div class="content">{{ data.score_bdn}}</div>
              </div>
              <div class="right">
                <div class="title">顺序背数:</div>
                <div class="content">{{ data.score_dsf}}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">顺序背数最大长度:</div>
                <div class="content">{{ data.score_dsf_max }}</div>
              </div>
              <div class="right">
                <div class="title">倒序背数:</div>
                <div class="content">{{ data.score_dsb}}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">倒序背数最大长度:</div>
                <div class="content">{{ data.score_dsb_max}}</div>
              </div>
              <div class="right">
                <div class="title">随机划消:</div>
                <div class="content">{{ data.score_car}}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">有序划消:</div>
                <div class="content">{{ data.score_car}}</div>
              </div>
              <div class="right">
                <!--                <div class="title">:</div>-->
                <!--                <div class="content">{{ data.score_co }}</div>-->
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">置信区间:</div>
                <div class="content">
                  {{ data.confidence_probability }}
                </div>
              </div>
              <div class="right">
                <div class="title">显著性水平:</div>
                <div class="content">{{ data.significance_level }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">比较基础:</div>
                <div class="content" >
                  <span v-if="data.comparison_basis == 1">全样本</span>
                  <span v-if="data.comparison_basis == 2">能力水平</span>
                </div>
              </div>
              <div class="right">
                <div class="title">计算方法:</div>
                <div class="content">
                  <span v-if="data.computing_method == 1">替代分测验</span>
                  <span v-if="data.computing_method == 2">比例估算法</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 简版-->
      <div v-if="data.edition == 5">
        <div>
          <div class="header" style="margin-top: 20px">
            <span>{{ title }}</span>
          </div>
          <div class="list">
            <div class="item">
              <div class="left">
                <div class="title">积木:</div>
                <div class="content">{{ data.score_bd }}</div>
              </div>
              <div class="right">
                <div class="title">类同:</div>
                <div class="content">{{ data.score_si }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">背数:</div>
                <div class="content">{{ data.score_ds }}</div>
              </div>
              <div class="right">
                <div class="title">译码:</div>
                <div class="content">{{ data.score_cd }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">矩阵推理:</div>
                <div class="content">{{ data.score_mr }}</div>
              </div>
              <div class="right">
                <div class="title">理解:</div>
                <div class="content">{{ data.score_co }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">置信区间:</div>
                <div class="content">
                  {{ data.confidence_probability }}
                </div>
              </div>
              <div class="right">
                <div class="title">显著性水平:</div>
                <div class="content">{{ data.significance_level }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="title">比较基础:</div>
                <div class="content" >
                  <span v-if="data.comparison_basis == 1">全样本</span>
                  <span v-if="data.comparison_basis == 2">能力水平</span>
                </div>
              </div>
              <div class="right">
                <div class="title">计算方法:</div>
                <div class="content">
                  <span v-if="data.computing_method == 1">替代分测验</span>
                  <span v-if="data.computing_method == 2">比例估算法</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { Detail } from "../../commonApi";
export default {
  components: {},
  props: {
    item: [Object],
    detailVisible: [Boolean],
  },
  watch: {
    item: {
      handler: function (e) {
        this.data = e;
        this.id = e.id;
        if (e.edition == 1) {
          this.title = "2:6 - 3:11";
        } else if (e.edition == 2) {
          this.title = "4:0 - 6:11";
        }
      },
      immediate: true,
      deep: true,
    },
    detailVisible: {
      handler: function (e) {
        this.visible = e;
        if (this.visible) {
          this.getDetail();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      title: "",
      visible: true,
      id: "",
      data: null,
      base: null,
      adaptiveAssessment: null,
      otherAdaptiveAssessment: null,
      type: "",
    };
  },
  created() {},
  methods: {
    async getDetail() {
      const res = await Detail({ id: this.id });
      if (res && res.code == 0) {
        this.data = res.wiscAssessment;
        this.base = {
          archives_num: res.wiscAssessment.archives_num,
          scoring_num: res.wiscAssessment.scoring_num,
          name: res.wiscAssessment.name,
          examiner_name: res.wiscAssessment.examiner_name,
          gender: res.wiscAssessment.gender,
          real_age: res.wiscAssessment.real_age,
          is_minorities: res.wiscAssessment.is_minorities,
          hand_habit: res.wiscAssessment.hand_habit,
          exam_address: res.wiscAssessment.exam_address,
          born_date: res.wiscAssessment.born_date,
          remark: res.wiscAssessment.remark,
          test_date: res.wiscAssessment.test_date,
          parent_culture:res.wiscAssessment.parent_culture,
          grade:res.wiscAssessment.grade,
        };
      }
    },
    cancel() {
      this.visible = false;
      this.$emit("detailCancel");
    },
  },
};
</script>
