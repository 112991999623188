import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import request from '@/utils/request';
Vue.config.productionTip = false;
Vue.use(Antd, {size: 'small'});
Vue.prototype.$axios = request;
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
// 地区选择

// 私有图片上传
import PrivatePictureUpload from '@/components/private-picture-upload';
Vue.component('private-picture-upload', PrivatePictureUpload);
Vue.filter('fmtPrice', function (value, num) {
  if (!value) return '';
  let tempValue = value.replace(/,/g, '')
  let tempPrice = Number(tempValue) * parseInt(num);
  return tempPrice.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
})
Vue.filter('fmtPrice3', function (value) {
  if (!value) return 0;
  return value.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
})

Vue.filter('fmtInt', function (value) {
  if (!value) return 0;
  return parseInt(value);
})


import bindList from './components/bindList';
Vue.component('bind-list', bindList);

import detail from './components/detail';
Vue.component('detail', detail);

import baseInfo from './components/detail/base';
Vue.component('baseInfo', baseInfo);


import TopMenu from './components/top-menu';
Vue.component('topMenu', TopMenu);

import footer from './components/footer';
Vue.component('footer-bottom', footer)

const vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

export default vm;
