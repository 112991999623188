<!--
  文件上传到阿里云OSS
-->
<template>
  <div style="display: flex">
    <div v-for="(item, index) in file_url" class="mr-5" style="position: relative;">
      <img @click="openPicturePreview(item)" :src="item.url + '?' + item.query" alt="" :style="wh">
      <a-icon type="close-circle" class="up-img-remove" @click="deleteFile(index)"
        style="position: absolute;top: -10px;right: -10px;font-size: 22px;cursor: pointer;color: #f50;" />
    </div>
    <a-upload list-type="picture-card" v-if="file_url.length < size" :show-upload-list="false" :customRequest="(e) => {uploadCustomRequest(e, 'preview');}">
      <div class="custom-upload">
        <a-icon type="plus" />
        <div class="ant-upload-text">{{text}}</div>
      </div>
    </a-upload>
    <!-- <div class="up-img-box" v-for="(item, index) in file_url" :key="index">
      <span v-if="item.indexOf('.mp4') == -1">
        <img :src="item + '?x-oss-process=image/resize,m_fixed,h_120,w_120'" class="up-img-item" />
        <a-icon type="close-circle" class="up-img-remove" @click="deleteFile(index)" />
      </span>
      <span v-else>
        <video :src="item" controls style="width: 110px;height: 103px;border: 1px dashed #d9d9d9"></video>
        <a-icon type="close-circle" class="up-img-remove" @click="deleteFile(index)" />
      </span>
    </div> -->
  </div>
</template>

<script>
  import axios from "axios";
  import { GetPrivatePictureSign, GetPrivateImageUrl } from '@/commonApi/index'
  export default {
    name: "private-picture-upload",
    props: {
      img_url: [String, Array],
      size: {
        type: Number,
        default: 1
      },
      accept: {
        type: String,
        default: 'image/*'
      },
      width:{
        type: String,
        default:'104px'
      },
      height:{
        type: String,
        default:'104px'
      },
      text:{
        type: String,
        default:'上传图片'
      },
    },
    mounted() {
    },
    watch: {
      img_url: {
        handler: function (e) {
          console.log(e)
          this.file_url = e ;
        },
        immediate: true,
        deep: true
      },
      size: {
        handler: function (e) {
          this.size = e;
        },
        immediate: true,
        deep: true
      },
      width: {
        handler: function (e) {
          this.wh.width = e;
        },
        immediate: true,
        deep: true
      },
      height: {
        handler: function (e) {
          this.wh.height = e;
        },
        immediate: true,
        deep: true
      }
    },
    data() {
      return {
        file_url: [],
        wh: {"width": this.width, "height": this.height}
      }
    },
    methods: {
      deleteFile(index) {
        let temp = this.file_url.splice(index, 1);
        this.$emit('pictureChange', {type: 'remove', list: temp.join(',')});
      },
      /**
       * 生成oss文件key
       */
      osskey(len) {
        len = len || 32;
        var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        var maxPos = chars.length;
        var pwd = "";
        for (var i = 0; i < len; i++) {
          pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
      },
      async uploadCustomRequest(e, type) {
        this.uploadFlag = type;
        try {
          // 获取 oss 签名
          const sign = await GetPrivatePictureSign();
          const { file } = e;
          let fileUrl = file["name"];
          let t = fileUrl.lastIndexOf(".");
          let fileType = fileUrl.substr(t);
          let fileName = this.osskey(15);
          let key = fileName + fileType;
          const { accessid, dir, expire, host, policy, signature } = sign;
          let form = new FormData();
          form.append("key", `${dir}${key}`);
          form.append("OSSAccessKeyId", accessid);
          form.append("policy", policy);
          form.append("Signature", signature);
          form.append("file", file);
          form.append("success_action_status", "200");
          const uploadRes = await axios.post(host, form, {
            "Content-Type": " multipart/form-data",
          });
          if (uploadRes.status == 200) {
            // this.file_url.push(host + "/" + dir + key);
            this.getPrivateImageUrl(dir + key)

            
          }
        } catch (error) {
          console.log(error)
          // this.$message.error("上传出错，请联系管理员");
        }
      },

      // 获取私有图片的url
      async getPrivateImageUrl(url) {
        let res = await GetPrivateImageUrl({objName: url});
        if (res && res["code"] == "0") {

          // 获取后的地址
          let url = `${res.url.protocol}://${res.url.host}${res.url.path}`;

          // 这期参数
          let query = res.url.query
          this.file_url.push({url: url, query: query});
          this.$emit('pictureChange', {type: 'add', list: this.file_url});
        } else {
          this.$message.error(res.message);
        }
      }
    }
  }
</script>

<style scoped>
  .up-img-box {
    position: relative;
    cursor: pointer;
    width: 120px
  }

  .up-img-item {
    width: 110px;
    height: 103px;
    border: 1px dashed #d9d9d9
  }

  .up-img-remove {
    position: absolute;
    right: 0px;
    top: -10px;
    color: #ff0000 !important;
    font-size: 24px;
  }
</style>