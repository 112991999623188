
<style scoped>
.header {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  padding-bottom: 20px;
  color: #832c75;
}
.list .item {
  display: flex;
  border-bottom: 1px solid #f8f8f8;
  line-height: 36px;
}
.list .item .left,
.list .item .right {
  flex: 1;
  display: flex;
}
.list .item .left .title,
.list .item .right .title {
  width: 120px;
  font-weight: bold;
}
.list .item .left .content,
.list .item .right .content {
  color: #666;
  flex: 1;
}
</style>
<template>
  <div class="list">
    <div class="item">
      <div class="left">
        <div class="title">诊疗卡号:</div>
        <div class="content">{{ base.archives_num }}</div>
      </div>
      <div class="right">
        <div class="title">少数民族:</div>
        <div class="content">
          <span v-if="base.is_minorities == 1">否</span
          ><span v-if="base.is_minorities == 2">是</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="left">
        <div class="title">记分编号:</div>
        <div class="content">{{ base.scoring_num }}</div>
      </div>
      <div class="right">
        <div class="title">用手习惯:</div>
        <div class="content">
          <span v-if="base.hand_habit == 1">左手</span
          ><span v-if="base.hand_habit == 2">右手</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="left">
        <div class="title">儿童姓名:</div>
        <div class="content">{{ base.name }}</div>
      </div>
      <div class="right">
        <div class="title">出生日期:</div>
        <div class="content">{{ base.born_date }}</div>
      </div>
    </div>

    <div class="item">
      <div class="left">
        <div class="title">儿童性别:</div>
        <div class="content">
          <span v-if="base.gender == 1">男</span
          ><span v-if="base.gender == 2">女</span>
        </div>
      </div>
      <div class="right">
        <div class="title">实际年龄:</div>
        <div class="content">{{ base.real_age }}</div>
      </div>
    </div>
    <div class="item">
      <div class="left">
        <div class="title">所在年级:</div>
        <div class="content">{{base.grade}}</div>
      </div>
      <div class="right">
        <div class="title">家长文化:</div>
        <div class="content">{{base.parent_culture}}</div>
      </div>
    </div>
    <div class="item">
      <div class="left">
        <div class="title">主试姓名:</div>
        <div class="content">{{ base.examiner_name }}</div>
      </div>
      <div class="right">
        <div class="title">测试日期:</div>
        <div class="content">{{ data.test_date }}</div>
      </div>
    </div>
    <div class="item">
      <div class="left">
        <div class="title">备注说明:</div>
        <div class="content">{{ base.remark }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: [Object],
  },
  watch: {
    data: {
      handler: function (e) {
        this.base = e;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      base: null,
    };
  },
  created() {
  },
  methods: {},
};
</script>
