<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <div v-if="$store.state.requestLoading" style="display: none; position: fixed;background: rgba(0,0,0,.2);display: flex;z-index: 19851112;display: flex;justify-content: center;align-items: center;width: 100%;height: 100%">
        <a-spin :delay="1500" tip="数据正在处理中..." size="large"></a-spin>
      </div>
      <router-view />
    </div>
  </a-locale-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
    return {
      locale: zhCN,
      intervalId: null
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>
<style lang="less">
  @import '~ant-design-vue/dist/antd.less'; // 引入官方提供的 less 样式入口文件
  .ant-menu::-webkit-scrollbar {
    display: none;
  }
</style>

<style>
</style>
