import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
        title: '登录'
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: () => import('@/views/forget-password/index.vue'),
        title: '忘记密码'
    },
    {
        path: '/activation',
        name: 'activation',
        component: () => import('@/views/activation/index.vue'),
        title: '新钥匙激活'
    },
    {
        path: '/maintain-data-modify',
        name: 'maintain-data-modify',
        component: () => import('@/views/maintain-data-modify/index.vue'),
        title: '维护-个人资料修改'
    },
    {
        path: '/maintain-password-modify',
        name: 'maintain-password-modify',
        component: () => import('@/views/maintain-password-modify/index.vue'),
        title: '维护-修改密码'
    },
    {
        path: '/maintain-history-report',
        name: 'maintain-history-report',
        component: () => import('@/views/maintain-history-report/index.vue'),
        title: '维护-历史报表查询'
    },
    {
        path: '/base-info',
        name: 'base-info',
        component: () => import('@/views/base-info/index.vue'),
        title: '录入-基本信息'
    },
    {
        path: '/select-age',
        name: 'select-age',
        component: () => import('@/views/select-age/index.vue'),
        title: '录入-选择年龄'
    },
    {
        path: '/complete',
        name: 'complete',
        component: () => import('@/views/complete/index.vue'),
        title: '完整版'
    },
    {
        path: '/simple',
        name: 'simple',
        component: () => import('@/views/simple/index.vue'),
        title: '简版'
    },
    {
        path: '/',
        redirect: '/index',
        title: '首页'
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        title: '首页'
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
