<template>
  <div class="menu-box">
    <div class="menu-item" :class="num == 1 ? 'active' : ''" @click="goIndex">
      <img src="/images/index-icon.png" alt="">
      <span>首页</span>
    </div>
    <div class="menu-item" :class="num == 2 ? 'active' : ''" @click="goBaseData">
      <img src="/images/input-icon.png" alt="">
      <span class="pr8">录入</span>
    </div>
    <div class="menu-item" :class="num == 3 ? 'active' : ''" @click="goMaintainDataModify">
      <img src="/images/maintain.png" alt="">
      <span>维护</span>
    </div>
    <div class="menu-item" :class="num == 4 ? 'active' : ''" @click="goExit">
      <img src="/images/exit-icon.png" alt="">
      <span>退出</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "top-menu",
  props: {
    num: {
      type: Number,
      default: 0
    },
  },
  watch: {
    num: {
      handler: function (e) {
        this.file_url = e.length > 0 ? e.split(',') : [];
      },
      immediate: true,
    }
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    goIndex() {
      this.$router.push({path: '/index'});
    },
    goBaseData() {
      this.$router.push({path: '/base-info'});
    },
    goActivation() {
      this.$router.push({path: '/select-age'});
    },
    goMaintainDataModify() {
      this.$router.push({path: '/maintain-data-modify'});
    },
    goExit() {
      let that = this;
      this.$confirm({
        title: '信息提示',
        content: '您确定要退出当前系统吗？',
        onOk() {
          window.localStorage.removeItem('login_code');
          that.$router.push({path: '/login'});
        },
        onCancel() {},
      });
    }
  }
}
</script>