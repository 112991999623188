import request from '../utils/request';
export const MobileReg = /^1[3-9]\d{9}$/;
export const EmailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
export const PhoneReg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/


// 私有图片上传
export function GetPrivatePictureSign() {
    return request({
        url: '/api/user/oss/getMiSign',
        method: 'GET',
    });
}

// 获取私有图片地址
export function GetPrivateImageUrl(params) {
    return request({
        url: '/api/user/oss/getUrl',
        method: 'GET',
        params: params
    });
}

// 获取个人信息
export function GetInfo() {
    return request({
        url: '/wisc/youth/baseInfo/getLoginInfo',
        method: 'GET',
    });
}


// 创建报告
export function Save(data) {
    return request({
        url: '/wisc/youth/report/save',
        method: 'POST',
        data
    });
}

// 更新报告
export function Update(data) {
    return request({
        url: '/wisc/youth/report/update',
        method: 'POST',
        data
    });
}

// 详情
export function Detail(params) {
    return request({
        url: '/wisc/youth/report/detail',
        method: 'GET',
        params: params
    });
}